import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  DialogContent,
  DialogActions,
  IconButton,
  Badge,
  InputAdornment,
  TextField,
} from "@mui/material";
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import { req, log } from "../utils";
import { useDataContext } from "../contexts/DataContext";
import PmJobNotes from "./PmJobNotes";
import KeyIcon from '@mui/icons-material/Key';
export default function PmQueue(props) {
  let defaultDistance =localStorage.getItem("maxKm")||50;
  const { updateProperty, addContextElement, machines,venueLocations } = useDataContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [jobsCount, setJobsCount] = useState(0); //the number of jobs in the queue
  const [pmQueue, setPmQueue] = useState([]);
  const [showJobModal, setShowJobModal] = useState(false);
  const [maxKm,setMaxKm] = useState(defaultDistance); //the maximum distance to show jobs
  const [selectedStop, setSelectedStop] = useState({}); //the selected stop from the pmQueue
  const [driverLocation, setDriverLocation] = useState({
    latitude: 0,
    longitude: 0,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getKMRanger = ()=>{
    return(<TextField
      label="Max distance"
      id="outlined-start-adornment"
      type="number"
      value={maxKm}
      onChange={(e) => setMaxKm(e.target.value)}
      size="small"
      fullWidth={true}
      sx={{ m: 1, width: '25ch' }}
      InputProps={{
        startAdornment: <InputAdornment position="start">KM</InputAdornment>,
      }}
    />);
  }
  const getJobs = async () => {
    try {
      let lat = driverLocation.latitude;
      let long = driverLocation.longitude;
      if(!lat || !long) return;
      //temp for me testing
      //lat = -33.852206;
      //long = 151.061551;
      //add local time for operating hours hh:mm:ss 24 hour format no am/pm
      let current_time =new Date().toTimeString().split(' ')[0];
      //current_date 0 = sunday 6 = saturday
      let current_day = new Date().getDay();
      let url = `get_pm_queue/?lat=${encodeURI(lat)}&long=${encodeURI(long)}&max_km=${maxKm}&current_time=${encodeURIComponent(current_time)}&current_day=${current_day}`;
      let queue = await req("GET", url);
      if(!queue) return;
      setJobsCount(queue.length);
      setPmQueue(queue);
    } catch (error) {
      log("Error in getJobs:", error);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openJobModal = (stop) => {
    try {
      //the selected stop
      //machines could be JSON so parse if not array
      if (typeof stop.machine_ids === "string") {
        stop.machine_ids = JSON.parse(stop.machine_ids);
      }

      setSelectedStop(stop);
      setShowJobModal(true);
    } catch (error) {
      log("Error in openJobModal:", error);
    }
  };

  const AusDate = (date) => {
    try {
      let d = new Date(date);
      return d.toLocaleDateString("en-AU");
    } catch (error) {
      log("Error in AusDate:", error);
      return "";
    }
  };
  const getMachineLocation = (location_id) => {
    try {
      let location = venueLocations.find(
        (location) => String(location.location_id) === String(location_id)
      );
      if (!location) return null;
      return location.location;
    } catch (error) {
      log("Error in getMachineLocation:", error);
      return "";
    }
  };
  const get_machine_details = (machine_id,selectedStop) => {
    
    try {
      let service_frequecies = JSON.parse(selectedStop.machine_service_frequencies);
      //get this machine's service frequency
      let service_frequency = service_frequecies.find(
        (sf) => String(sf.machine_id) === String(machine_id)
      );
      console.log("service_frequency",service_frequency);
      let machine = machines.find(
        (machine) => String(machine.machine_id_pk) === String(machine_id)
      );

      if (!machine) return null;
      return (
        <Grid
          style={{
        borderBottom: "solid 1px rgba(0,0,0,.15)",
        marginBottom: ".7rem",
        paddingBottom: ".5rem",
        textAlign: "center",
          }}
          container
          spacing={2}
          key={"machine-row-" + machine_id}
        >
          <Grid item xs={3}>
        {machine.serial_id} - {machine.machine_type}
          </Grid>
          <Grid item xs={3}>
        {getMachineLocation(machine.location_id) || "No data"}
          </Grid>
          <Grid item xs={3}>
        {AusDate(machine.last_service_date) || "No data"}
          </Grid>
          <Grid item xs={3}>
        {service_frequency.service_frequency_based_on_bottle_counts || "No data"}
          </Grid>
        </Grid>
      );
    } catch (error) {
      log("Error in get_machine_details:", error);
      return null;
    }
  };

  const formatDistance = (distance) => {
    try {
      if (distance < 1) {
        distance = Math.round(distance * 1000);
        distance = `${distance}m`;
      } else {
        distance = Math.round(distance);
        distance = `${distance}km`;
      }
      return distance;
    } catch (error) {
      log("Error in formatDistance:", error);
      return "";
    }
  };

  const takeJob = async (stop) => {
    try {
      setShowJobModal(false); //hide modal
      let url = `take_pm_job/`;
      const time = new Date().getTime()/1000;
      let response = await req("POST", url,{stop_id:stop.id,time});
      console.log("take_pm_job", response);
      if (response.result) {
        alert("Congratulations, you have taken the job. We'll refresh the page to see it in your schedule.");
        //update schedeule I will refresh the page for now.
        window.location.reload();
        return;
      } else {
        alert("Sorry, something went wrong or the job was already taken");
        log("Sorry, something went wrong or the job was already taken");
        return;
      }
    } catch (error) {
      log("Error in takeJob:", error);
    }
  };

  useEffect(() => {
    let watcher = null;
    const watchLocation = () => {
      if ("geolocation" in navigator && navigator.geolocation) {
        try {
          watcher = navigator.geolocation.watchPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              setDriverLocation({ latitude, longitude });
            },
            (error) => {
              console.error("Error watching position:", error);
            },
            {
              enableHighAccuracy: true,
              timeout: 5000,
              maximumAge: 0,
            }
          );
        } catch (error) {
          console.error("Error setting up geolocation watch:", error);
        }
      } else {
        console.warn("Geolocation is not available on this device");
      }
    };

    watchLocation();

    // Enhanced cleanup with type checking and error handling
    return () => {
      if (typeof watcher === 'number' && navigator.geolocation) {
        try {
          navigator.geolocation.clearWatch(watcher);
        } catch (error) {
          console.error("Error clearing geolocation watch:", error);
        }
      }
    };
  }, []);
  useEffect(() => {
    getJobs();
    localStorage.setItem("maxKm",maxKm);
  }, [driverLocation,maxKm]);

  const formatButton = (stop, index) => {
    try {
      let distance = formatDistance(stop.distance);

      let color = "rgba(0, 131, 124, 1)";
      if (stop.urgency_score >= 4 && stop.urgency_score <= 6) {
        color = "rgba(255, 204, 0, 1)";
      } else if (stop.urgency_score >= 7) {
        color = "rgba(217, 48, 37, 1)";
      }
      return (
        <MenuItem
          onClick={() => openJobModal(stop)}
          key={index}
          style={{ backgroundColor: color, color: "white" }}
        >
          <Grid container>
            <Grid item xs={10}>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                 {stop?.access_restrictions && selectedStop?.access_restrictions?.length > 0 && (
          <KeyIcon style={{marginRight:"10px",fontSize:"14px"}}/>)}

                {stop.venue_name}
              </div>
            </Grid>
            <Grid item xs={2}>
              {distance}
            </Grid>
          </Grid>
        </MenuItem>
      );
    } catch (error) {
      log("Error in formatButton:", error);
      return null;
    }
  };
  return (
    <div>
      <IconButton onClick={handleClick} color="inherit">
        <Badge badgeContent={jobsCount} color="inherit">
          <BuildCircleIcon />
        </Badge>
      </IconButton>
      <Menu
  id="pm-menu"
  anchorEl={anchorEl}
  open={Boolean(anchorEl)}
  onClose={handleClose}
>
   <MenuItem>
    {getKMRanger()}
    </MenuItem>
  {pmQueue && pmQueue.length > 0 
    ? pmQueue.map((queue, index) => formatButton(queue, index))
    : <MenuItem>No jobs available in your location</MenuItem>
  }
</Menu>


      <Dialog open={showJobModal} onClose={() => setShowJobModal(false)} fullWidth> 
        <DialogTitle>
          Prevenative Maintenance: Due {AusDate(selectedStop.pm_due_date)}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ textAlign: "center", borderBottom: "solid 1px rgba(0,0,0,.15)",
            marginBottom: ".7rem",
            paddingBottom: ".5rem"}}>
            <Grid item xs={3}>Machine</Grid>
            <Grid item xs={3}>Location</Grid>
            <Grid item xs={3}>Last service</Grid>
            <Grid item xs={3}>Service frequency</Grid>
          </Grid>
          {selectedStop.machine_ids &&
            selectedStop.machine_ids.map((machine, index) =>
              get_machine_details(machine,selectedStop)
            )}
          <Grid container spacing={2} style={{ textAlign: "center" }}>
            <div style={{color:"grey",padding:".5rem"}}>Reason: {selectedStop.reason_added_to_list}</div>
            {selectedStop?.access_restrictions && selectedStop?.access_restrictions?.length > 0 && (
          <Grid style={{fontWeight:"bolder",color:"red"}} item xs={12}>Access Restrictions: {selectedStop.access_restrictions}</Grid>)}
            <Grid item xs={8}>
              <Button
                variant="link"
                color="persiangreen"
                fullWidth
                href={`https://www.google.com/maps/search/?api=1&query=${selectedStop.latitude},${selectedStop.longitude}`}
                target="_blank"
              >
                {selectedStop.venue_name} {formatDistance(selectedStop.distance)}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <PmJobNotes pm={selectedStop} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="persiangreen"
            autoFocus
            onClick={() => setShowJobModal(false)}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="persiangreen"
            fullWidth
            onClick={() => takeJob(selectedStop)}
          >
            Take the job
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
